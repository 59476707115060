<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formTransportador" lazy-validation>
      <v-container grid-list-md fluid>
        
        <v-widget title="Informações do Transportador" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="CNPJ / CPF *" v-model="transportador.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" :rules="validarCnpjCpf" required data-cy="cnpj_cpf"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Inscrição Estadual *" v-model="transportador.inscricao_rg" :rules="validarInscricao" data-cy="inscricao_rg"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Código ANT" v-model="transportador.codigo_ant" data-cy="codigo_ant"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Razão Social / Nome *" v-model="transportador.razao_nome" :rules="validadarRazaoNome" required data-cy="razao_nome"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Veículos" class="mt-4" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="Placa *" v-model="placa" v-mask="'AAA-####'" required data-cy="placa"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete label="UF *" v-model="uf_placa" :items="constantes.ESTADOS_AUTOCOMPLETE" data-cy="uf"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn color="primary" @click="adicionarVeiculo()" :disabled="!podeAdicionarVeiculo" data-cy="adicionar">Adicionar</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-data-table :headers="headers" :items="transportador.veiculos" hide-default-footer item-key="placa" >
                  <template v-slot:item="props">
                    <tr>
                      <td>{{ props.item.placa | formatarPlaca }}</td>
                      <td>{{ props.item.uf }}</td>
                      <td class="text-center"><v-icon @click="removerVeiculo(props.item)" data-cy="removerVeiculo">delete</v-icon></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-widget>

        <v-widget title="Endereço" class="mt-4" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="4">
                <campo-cep v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="transportador.cep" append-icon="map"></campo-cep>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Logradouro *" v-model="transportador.logradouro" :rules="validarLogradouro" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Número *" v-model="transportador.numero" :rules="validarNumero" required data-cy="numero"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                  <v-text-field label="Complemento" v-model="transportador.complemento" data-cy="complemento"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Bairro *" v-model="transportador.bairro" :rules="validarBairro" :readonly="cepValido" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete label="Estado" v-model="transportador.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(transportador.estado)"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete label="Cidade *" v-model="transportador.codigo_cidade" @change="setCidade(transportador.codigo_cidade)" :items="cidades" :rules="validarCidade" :disabled="cidades.length == 0 || $store.state.transportador.carregando_cidade" :loading="$store.state.transportador.carregando_cidade"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="País *" v-model="transportador.pais" :rules="validarPais" :readonly="true" required></v-text-field>
              </v-col>
            </v-row>
          </div>

          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>

      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';

export default {

  data: () => ({

    valid: false,
    validarCnpjCpf: [v => (v && v.length === 14 ? validador.cpf(v): (v.length === 18 ? validador.cnpj(v): false)) || "CNPJ/CPF Inválido"],
    validarInscricao: [v => !!v || "Inscrição Obrigatória"],
    validadarRazaoNome: [v => !!v || "Razão/Nome Obrigatório"],
    validarCep: [v => !!v || "CEP Obrigatório"],
    validarLogradouro: [v => !!v || "Logradouro Obrigatório"],
    validarNumero: [v => !!v || "Número Obrigatório"],
    validarBairro: [v => !!v || "Bairro Obrigatório"],
    validarCidade: [v => !!v || "Cidade Obrigatória"],
    validarPais: [v => !!v || "País Obrigatório"],
    constantes: constantes,
    placa: '',
    uf_placa: '',
    loading: false,
    headers: [{text: 'Placa', value: 'placa'},
              {text: 'UF', value: 'uf'},
              { text: 'Remover', value: 'name', align:'center',  sortable: false }]
  }),

  computed: {

    ...mapState('transportador', {
      transportador: 'transportadorAtual',
      carregando: 'carregando',
      cepValido: 'cepValido',
    }),
    
    ...mapGetters('transportador', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      cidades: 'listaCidades',
    }),

    podeAdicionarVeiculo(){
      if(this.placa && this.placa !== '' && this.uf_placa && this.uf_placa !== '' && validador.sigla(this.uf_placa)){
        return true;
      }
      else{
        return false;
      }
    }
  },

  methods: {

    ...mapActions('transportador', {
      filtrarCidadesEstado: 'filtrarCidadesEstado'
    }),

    ...mapMutations('transportador', {
      setCidade: 'setCidade'
    }),
    
    async salvar() {
      if (this.$refs.formTransportador.validate()) {
        
        try{

          let inscricao = `${this.transportador.inscricao_rg}-${this.transportador.estado}`;
          let isInscricaoValida = validador.inscricaoEstadual(inscricao);
          if(isInscricaoValida){
            await this.$store.dispatch('transportador/salvar');
            this.$router.push({name: 'transportadores'});
          }
          else{
            this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
          }
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar(){
      this.$router.push({name: 'transportadores'});
    },

    setEndereco(endereco){
      this.$store.commit('transportador/setEndereco', endereco);
      this.filtrarCidadesEstado(this.transportador.estado);
    },

    adicionarVeiculo(){
      this.$store.commit('transportador/adicionarVeiculo', {placa: this.placa, uf: this.uf_placa});
      this.placa = '';
      this.uf_placa = '';  
    },

    removerVeiculo(veiculo){
      this.$store.commit('transportador/removerVeiculo', veiculo);
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('transportador/setCidades', []);
    next();
  },

  async created(){

    try{

      this.loading = true;

      let transportador = this.$store.state.transportador.transportadorAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if(estaEditando && (!transportador._id || transportador._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um transportador para editar');
        this.$router.push({name: 'transportadores'});
      }else if(estaEditando){
        await this.filtrarCidadesEstado(this.transportador.estado);
      }

      if(!estaEditando){
        this.$store.commit('transportador/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>